<template>
    <div class="root" id="root">
        <h1 class="title">Basket</h1>
        <div class="products">
            <div v-for="product in getBasket" :key="product.index" class="product">
                    <div class="thb-wrapper">
                    <div class="thb" :style="'background-image:url(\'' + product.image.url + '\');' + 'width:' + thbWidth( product.width, product.height ) + 'px;' + 'height:' + thbHeight( product.width, product.height ) + 'px;'">
                    </div>
                </div>
                <div class="details">
                    <div class="description" v-html="product.image.description"></div>
                    <div class="id" v-html="'Image ID: ' + product.image.id"></div>
                    <div class="type" v-html="product.type"></div>
                    <div class="width" v-html="'Width: ' + product.width + 'mm'"></div>
                    <div class="height" v-html="'Height: ' + product.height + 'mm'"></div>
                </div>
                <div class="price">
                    &pound;{{ product.cost }}
                </div>
                <div class="delete" @click="deleteProduct( product.id )">
                    Delete
                </div>
            </div>
        </div>
        <div class="total-wrapper">
            <div class="total">
                &pound;{{ getTotal }}<br />
            </div>
        </div>
        <div class="checkout-wrapper">
            <div class="checkout">CHECKOUT AND PAY</div>
            <router-link to="/"><div class="shop">Continue shopping</div></router-link>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
    methods:{
        ...mapMutations([
            'deleteProduct',
            'enablePopularSearches'
        ]),
        thbHeight:function(width, height){
            if( parseInt(height) < parseInt(width) ){
                console.log(( height / width ) * 150)
                return ( height / width ) * 150
            } else {
                return 150;
            }
            
        },
        thbWidth:function(width, height){
            if( parseInt(height) > parseInt(width) ){
                console.log(( width / height ) * 150)
                return ( width / height ) * 150
            } else {
                return 150;
            }
        },
        deleteProduct: function(id){
            this.$store.commit( 'deleteProduct', {id: id} )
        },
    },
    computed:{
        ...mapGetters([
            'getBasket'
        ]),
        getTotal: (store) => {
            var total = 0;
            store.getBasket.forEach(product => {
                total += parseFloat(product.cost)
            })
            return total.toFixed(2)
        }
    },
    mounted:function(){
        this.$store.commit( 'enablePopularSearches' )
        this.$nextTick(function () {
            document.getElementById('app').setAttribute("style", "height: " + ( document.getElementById('root').getBoundingClientRect().height + 365 ) + 'px' )
        })
    }

}
</script>

<style scoped>
    .root{
        margin: 1rem 1.3rem;
        background-color: white;
        
    }

    .thb{
        background-size: cover;
        background-position: center;
        margin: auto;
        border: 1px solid black;
    }

    .product{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 1rem 1rem 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid black;
    }

    .details{
        width: 50vw;
    }

    .details > div{
        margin-bottom: 0.45rem;
    }

    .delete{
        width: 10rem;
        padding: 1rem;
        background-color: rgb(143,44,140);
        text-align: center;
        margin: 2rem;
        font-weight: bolder;
        text-transform: uppercase;
        color: white;
        border-radius: 0.5rem;
        cursor: pointer;
    }

    .thb-wrapper{
        width: 150px;
        margin: 0 1rem;
    }
    
    .title{
        text-align: center;
        padding: 0.5rem;
    }

    .total-wrapper{
        border-top: 1px solid black;
        margin: 0 1rem;
    }

    .total{
        text-align: right;
        margin: 1rem 15rem 1rem 0;
    }

    .checkout, .shop{
        margin: 2rem 2rem 2rem 2rem;
        padding: 1rem;
        background-color: white;
        border-radius: 0.5rem;
        border: 2px solid rgb( 143,44,140 );
        width: calc(100% - 8rem);
        text-align: center;
        font-size: 1.3rem;
        cursor: pointer;
    }

    .shop{
        background-color: rgb( 143,44,140 );
        color: white;
    }

    a{
        text-decoration: none;
    }

    .checkout-wrapper{
        padding-bottom: 1rem;
    }

    .product:last-child{
        margin-bottom: 0.25rem;
    }
</style>